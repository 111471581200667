import React from 'react';
import './Blog.css'

export default function Blog() {
  return (
    <>
      <div className='blog-container'>
        <h1 className='blog'>Nothing here yet.</h1>
      </div>
    </>
  );
}
