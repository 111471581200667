import React from 'react';
import './Projects.css'
import ProjectComponent from '../../ProjectComponent.js';
import { budgetTracker, eAuctionApp, passwordGenerator, twitterBot } from './Data';

function Projects() {
  return (
    <>
      <div className='page-container content darkBg'>
        <div className='inner-container hero'>
          <h1 id='main-heading'>Recent Projects: Some of my work from the past year.</h1>
        </div>

        {/* This is where the projects start */}
        <div className='border project-list'>
          <div className='inner-container'>
            <h4 id='sub-heading'>Latest Projects:</h4>
            <ul className='post-list no-bullet'>
              <ProjectComponent {...budgetTracker} />
              <ProjectComponent {...eAuctionApp} />
              <ProjectComponent {...passwordGenerator} />
              <ProjectComponent {...twitterBot} />
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Projects;