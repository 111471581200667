import React from 'react';
import HomeSection from '../../HomeSection';
import {homeObjOne} from './Data';

function Home() {
  return (
    <>
      <HomeSection {...homeObjOne} />
    </>
  )
}

export default Home;
