import './Cursor.css';

function Cursor() {

  return (
    <>
      <span className='logo-cursor'></span>
    </>
  );
}

export default Cursor;