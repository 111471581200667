import React from 'react';
import './ProjectComponent.css';
import { FaArrowRight } from 'react-icons/fa';
import Label from './Label';

function ProjectComponent({
  lightBg,
  niceOrange,
  title,
  description,
  link,
  tagline,
  tags,
  project_icon,
  img,
  alt

}) {

  // const Label = (tags) => tags.map((item) => 
  //   <li className='tags'><label>{ item }</label></li>
  // );

  return (
    <>
      <li className='project-start border-bottom no-bullet'>
        <a className='transition-link' href='/projects/'></a>
        <div className='card'>
          <a className='transition-link' href={link}>
              <img src={img} alt={alt}></img>
          </a>
          <a className='cta-button overlay-button transition-link' href={link}>
            See Projects
            <span className='arrow-right'><FaArrowRight /></span>
          </a>
          <a className='overlay transition-link' href={link}></a>
        </div>
        <a className='transition-link' href={link}>
        <h3 className='project-title'>{ title }</h3>
        </a>
        <h4>{ tagline }</h4>
          <Label tags={tags}/>
      </li>

    </>
  )
}

export default ProjectComponent;