import React from 'react';
import Footer from '../components/footer'
import Links from '../components/Links'

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Wrapper>
        <Footer.Row>
          <Footer.Column>
            <Footer.Title>
              Alan Figueroa. All rights reserved.
            </Footer.Title>
          </Footer.Column>
          <Footer.Column>
            <Links />
          </Footer.Column>
        </Footer.Row>
      </Footer.Wrapper>
    </Footer>
  )
}