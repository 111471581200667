import './Links.css';
import { FaGithub, FaLinkedin, FaTwitterSquare, FaKey} from 'react-icons/fa';

function Links() {
  return (
    <div className='wrapper'>
          <a className='button' href='https://github.com/figgyfigs'> 
            <div className='icon'><FaGithub className='svg' /></div>
            <span>Github</span>
          </a>
          <a className='button' href='https://linkedin.com'> 
            <div className='icon'><FaLinkedin className='svg' /></div>
            <span>Linkedin</span>
          </a>
          <a className='button' href='https://keybase.io/'>
            <div className='icon'><FaKey className='svg' /></div>
            <span>Keybase</span>
          </a>    
    </div>
  );
}

export default Links;