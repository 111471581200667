import React from 'react';
import ContactForm from '../../ContactForm.js'

function Contact() {
  return (
    <>
        <ContactForm />
    </>
  )
}

export default Contact;