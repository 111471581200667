import React from 'react';
import './HomeSection.css'

function Home({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  section_titles,
  about_array,
  interests,
  description,
  img,
  alt,
  imgStart,
}) {

  const listItems = (array) => array.map((item) => 
    <li>{item}</li>
  );

  return (
    <>
      <div className={lightBg ? 'home-section' : 'home-section darkBg'}>
        <div className='container'>
          <div className='row home-row' style={{display: 'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}>
          <div className='col'>
              <div className='home-img-wrapper'>
                <img src={img} alt={alt} className='home-img' />
              </div>
            </div>
            <div className='col'>
              <div className='home-text-wrapper'>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <div className='section-container'>
                <h2 className='nice-orange headers'>
                  {section_titles[0]}
                </h2>
                <div className='bullet-points'>
                <p>
                    <li>{ description }</li>
                </p>
                </div>
                </div>
                <div className='section-container'>
                  <h2 className='nice-orange headers'>
                    {section_titles[1]}
                  </h2>
                    <ul>
                      <div className='bullet-points'>
                        {listItems(about_array)}
                      </div>
                    </ul>
                </div>
                <div className='section-container'>
                  <h2 className='nice-orange headers'>{section_titles[2]}</h2>
                  <ul>
                    <div className='bullet-points'>
                      {listItems(interests)}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className='col'>
              <div className='home__hero-img-wrapper'>
                <img src={img} alt={alt} className='home__hero-img' />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;