import React from 'react';
import './ContactForm.css';
import Contact from './pages/Contact/Contact';

function ContactForm() {
    return (
        <>
            <div className='contact-section'>
                <h1>Want to connect?</h1>
                <h1>Send me a message!</h1>
                <div className='contact-border'></div>
                <form className='contact-form' name='contact' method='post'>
                    <input type="hidden" name="form-name" value="contact" />
                    <input className='form-text' name='name' type='text' placeholder='Your name'></input>
                    <input className='form-text' name='email' type='email' placeholder='Your email'></input>
                    <textarea className='form-textarea' name='message' placeholder='Enter your message here'></textarea>
                    <input className='form-button' name='submit' type='submit' value='Send' />
                </form>
            </div>

        </>
    )
}

export default ContactForm;
