import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom'
import './Navbar.css';
import Dropdown from './Dropdown';
import Cursor from './Cursor';
import { FaGreaterThan, FaTimes, FaBars } from 'react-icons/fa';
import { IconContext} from 'react-icons/lib';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
    return (
      window.removeEventListener('resize', showButton)
    )
  }, []);

  const onMouseEnter = () => {
    if(window.innerWidth <= 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if(window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff'}}>
      <nav className='navbar'>
        <div className='navbar-container container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <FaGreaterThan className='navbar-icon' />
          figsalan <Cursor /> 
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/projects' className='nav-links' onClick={closeMobileMenu}>
              Projects
            </Link>
          </li>
{/*       
          <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link to='/hobbies' className='nav-links' onClick={closeMobileMenu}>
              Hobbies <i className='fas fa-caret-down'></i>
            </Link>
              {dropdown && <Dropdown />}
          </li>
*/}          
          <li className='nav-item'>
            <Link to='/blog' className='nav-links' onClick={closeMobileMenu}>
              Blog
            </Link>
          </li>
          <li className='nav-btn'>
                {button ? (
                  <Link to='/contact-me' className='btn-link'>
                    <Button buttonStyle='btn--outline'>Contact</Button>
                  </Link>
                ) : (
                  <Link to='/contact-me' className='btn-link' onClick={closeMobileMenu}>
                    <Button
                      buttonStyle='btn--outline'
                      buttonSize='btn--mobile'
                    >
                      Contact
                    </Button>
                  </Link>
                )}
              </li>
{/*          
          <li className='nav-item'>
            <Link to='/contact' className='nav-links-mobile' onClick={closeMobileMenu}>
              Contact Me
            </Link>
          </li>
        </ul>
        <Button />
*/}
          </ul>
        </div>
      </nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar;