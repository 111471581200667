import './Label.css';

function Label(props) {

  const array = props.tags;
  const tags = array.map((item) => <li className='tag'>{ item }</li>)

  return (
    <>
      <ul className='tags'>
        {tags}
      </ul>
    </>
  )
};

export default Label;