export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  niceOrange: true,
  headline: 'Hello, my name is Alan. ',
  description: 'This site was created by me. Here you can find more information about me, my hobbies, my topics of interest, and projects I have worked on.',
  section_titles: ['About this site', 'About me', 'Interests'],
  about_array: ["I enjoy writing code, debugging, and learning new techniques in the process", "I have an undergraduate degree in Computer Science from San Diego State University", "I love coffee"],
  interests: ['bitcoin', 'privacy', 'economics', 'cryptography', 'open source projects', 'everything coffee'],
  imgStart: 'False',
  img: 'images/closeup.jpg',
  alt: 'me'
};