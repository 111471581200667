export const budgetTracker = {
  lightBg: false,
  niceOrange: true,
  title: "Budget Tracker",
  img: "images/budget-tracker.jpg",
  link: "https://figgyfigs.github.io/Budget-Tracker/",
  alt: "Budget Tracker",
  tagline: "Keep your finances on track.",
  tags: ["Javascript", "HTML", "CSS", "Frontend Development"]
}

export const eAuctionApp = {
  title: "eAuctionApp",
  img: "images/commerce.jpg",
  link: "https://github.com/figgyfigs/eAuction-App/blob/master/auctions/views.py",
  alt: "auction",
  tagline: "Auction off unwanted items",
  tags: ["Python", "Django", "SQLite", "Bootstrap", "Backend Development"]
}

export const passwordGenerator = {
  title: "Password Generator",
  img: "images/password-generator.jpg",
  link: "https://figgyfigs.github.io/password-generator/",
  alt: "password",
  tagline: "Keep your accounts secure",
  tags: ["Javascript", "HTML", "CSS"]
}

export const twitterBot = {
  title: "Twitter Bot",
  img: "images/twitter-bot.jpg",
  link: "https://twitter.com/BitcoinCuration",
  alt: "twitter",
  tagline: "Twitter bot that keeps track of incoming blocks",
  tags: ["Python", "Twitter API"]
}